import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import NotFound from "./NotFound";

const ErrorBoundary = ({ children }) => {
  const errorHandler = (error, errorInfo) => {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={NotFound}
      onError={errorHandler}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
