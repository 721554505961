import React, { useEffect, useRef, useState } from "react";
import Layouts from "../ant-design/templates/domesticCheckout/Layouts";
import MobileLayout from "../ant-design/templates/domesticCheckout/mobileLayout/MobileLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  collpaseAddressComponent,
  handleIsShippingAddressDisplay,
  paymentSession,
  selectTemplate,
  setIsSessionInvalid,
  setIsSessionPaid,
  updateBrowserCountry,
} from "../slices/PaymentSessionDetailsSlice";
import {
  deleteCookie,
  generateDynamicStyling,
  getCookieValue,
  getStroage,
  isEmpty,
  updateStroage,
} from "../Utils/utils";
import {
  selectIsMobileLayout,
} from "../slices/MobileLayoutSlice";
import Navbar from "../ant-design/resuableComponents/navBar/Navbar";
import PaymentSuccess from "../ant-design/components/paymentSuccess/PaymentSuccess";
import { Route, Routes } from "react-router-dom";
import {
  handleContactNumberField,
  handleEmailAddress,
  handleExpiryTime,
  handleFullNameField,
  handleLoginComponent,
  handleMerchantLogo,
  handleOrderItem,
  handleTotalPaymentSummaryField,
  handlePanField,
  handleDobField,
  handleTotalPaymentSummaryValue
} from "../slices/HandleAdditionalComponentsSlice";
import { showFailedModal } from "../slices/PaymentInitiationSlice";
import Loading from "../ant-design/components/loadingScreen/Loading";
import PaymentCompletionHandler from "../ant-design/components/paymentCompletionHandler/PaymentCompletionHandler";
import { PaymentProvider } from "../context/payment.context";
import timeZoneToCountry from "../staticData/timeZoneToCountry";
import { CountryData } from "../staticData/countryData";
import { handleGuestCheckout, setIsLoggedIn } from "../slices/ShopperInfoSlice";

const BoxPayCheckout = () => {
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const [token, setToken] = useState(null);
  const dispatch = useDispatch();
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const template = useSelector((state) => selectTemplate(state));
  const isIframePopUp = window.opener && window.opener !== window && window.opener.self !== window.opener.top;
  const parsedUrl = new URL(window.location.href);
  const params = new URLSearchParams(parsedUrl.search);
  const paramsObject = {};
  const windowPopupName = window?.name;

  const showTotalPaymentSummaryField = useSelector((state) => state.additionalComponents.showTotalPaymentSummaryField);
  const onDemandAmount = useSelector((state) => state.commonPersisted.onDemandAmount);



  // Iterate over all query parameters
  for (const [key, value] of params.entries()) {
    paramsObject[key] = decodeURIComponent(value);
  }
  function sendDataToMainAppFromPopWindow() {
    if (window.opener) {
      window.opener.postMessage(
        { type: 'popupData', data: paramsObject },  // Data to be sent
        '*'  // Wildcard can be replaced by a specific origin
      );
    }
  }

  useEffect(() => {
    if (isIframePopUp && params) {
      sendDataToMainAppFromPopWindow();
    }
  }, [params]);

  useEffect(() => {
    const currentUrl = window.location.pathname;
    if (currentUrl === '/payment-completion-handler') return

    const searchParams = new URLSearchParams(document.location.search);
    if (searchParams.get("token")) {
      if (searchParams.get("token") !== getStroage("session", "tkn")) {
        updateStroage("session", "clear");
      }

      setToken(searchParams.get("token"));
    } else if (currentUrl === "/finalStatus") {
      setToken(sessionStorage.getItem("token"));
    } else if (currentUrl) {
      let result = currentUrl.lastIndexOf("/");
      if (result > -1) {
        const token = currentUrl.substring(result + 1);
        if (token !== getStroage("session", "tkn")) {
          updateStroage("session", "clear");
        }
        setToken(token);
      }
    }
    window.addEventListener("pageshow", function (event) {
      var historyTraversal =
        event.persisted ||
        (typeof window.performance != "undefined" &&
          window.performance.navigation.type === 2);
      if (historyTraversal) {
        // Handle page restore.
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    if (!token && token === null) {
      return;
    }

    // shopper token
    let shopperToken = getCookieValue('shopper_token') || localStorage.getItem("sessionToken");
    if (shopperToken) {
      localStorage.setItem("sessionToken", shopperToken);
      deleteCookie("shopper_token");
      dispatch(handleGuestCheckout(false));
    }

    updateStroage("session", "update", "tkn", token);
    if (token) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const countryName = timeZoneToCountry[timeZone];
      let countryObj = CountryData.find(d => d.name.toLowerCase() === countryName.toLowerCase());
      const countryCode = countryObj?.code;
      dispatch(updateBrowserCountry(countryObj || {}));
      dispatch(paymentSession({ token, countryCode }));
    }
    else {
      dispatch(setIsSessionInvalid(true));
    }
  }, [token]);

  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (sessionDetails?.token === "PAID") {
      dispatch(setIsSessionPaid(true));
    } else {
      dispatch(setIsSessionPaid(false));
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (sessionDetails?.status === "PROCESSING") {
      dispatch(setIsSessionPaid(true));
    } else {
      dispatch(setIsSessionPaid(false));
    }
  }, [sessionDetails]);


  useEffect(() => {
    if (onDemandAmount) {
      dispatch(handleTotalPaymentSummaryValue(onDemandAmount))
    }
  }, [onDemandAmount])


  useEffect(() => {
    const additionalFieldSets = sessionDetails?.configs?.additionalFieldSets;
    const customFields = sessionDetails?.merchantDetails?.customFields;
    if (additionalFieldSets && sessionDetails?.paymentDetails) {
      // shopper token
      let shopperToken = getCookieValue('shopper_token') || localStorage.getItem("sessionToken");
      dispatch(handleLoginComponent(shopperToken ? false : additionalFieldSets.includes("SHOPPER_LOGIN")));
      dispatch(setIsLoggedIn(!isEmpty(shopperToken)));
      dispatch(handleIsShippingAddressDisplay(additionalFieldSets.includes("SHIPPING_ADDRESS")));
      dispatch(handleMerchantLogo(additionalFieldSets.includes("MERCHANT_LOGO")));
      dispatch(handleExpiryTime(additionalFieldSets.includes("TIMER")));
      dispatch(handleOrderItem(additionalFieldSets.includes("ORDER_ITEM_DETAILS")));
      dispatch(handleEmailAddress(additionalFieldSets.includes("SHOPPER_EMAIL")));
      dispatch(handleFullNameField(additionalFieldSets.includes("SHOPPER_NAME")));
      dispatch(handleContactNumberField(additionalFieldSets.includes("SHOPPER_PHONE")));
      dispatch(handleTotalPaymentSummaryField(sessionDetails?.paymentDetails?.onDemandAmount === true));
    }
    if (customFields && sessionDetails?.paymentDetails) {
      customFields.forEach((d => {
        if (d.fieldName === "PAN") dispatch(handlePanField(true));
        if (d.fieldName === "DATE_OF_BIRTH") dispatch(handleDobField(true));
      }))
    }

    if (additionalFieldSets && sessionDetails?.paymentDetails && !additionalFieldSets.includes("SHIPPING_ADDRESS")
      && !additionalFieldSets.includes("SHOPPER_EMAIL")
      && !additionalFieldSets.includes("SHOPPER_NAME")
      && !additionalFieldSets.includes("SHOPPER_PHONE")) {
      dispatch(collpaseAddressComponent())
    }
  }, [sessionDetails, document.cookie]);

  const templateDictionary = {
    domestic_checkout: {
      navBar: <Navbar />,
      checkout: isMobileLayout ? <MobileLayout /> : <Layouts />,
      success: <PaymentSuccess />,
      paymentcompletionhandler: <PaymentCompletionHandler />
    },
  };
  const randomParamsForHmh = new URLSearchParams(document.location.search);
  const hmh = randomParamsForHmh.get("hmh");
  const randomParams = new URLSearchParams(document.location.search);
  const pns = randomParams.get("pns");
  useEffect(() => {
    if (pns) {
      dispatch(showFailedModal(true));
    }
    if (hmh) {
      const url = new URL(window.location.href);
      url.searchParams.set("hmh", hmh);
      window.history.pushState({}, "", url.toString());
    }
  }, [pns]);

  const getTemplate = (template) => {
    let layout, success, navBar, paymentcompletionhandler;
    if (template in templateDictionary) {
      layout = templateDictionary[template]?.checkout;
      success = templateDictionary[template]?.success;
      paymentcompletionhandler = templateDictionary[template]?.paymentcompletionhandler;
      navBar = templateDictionary[template]?.navBar;
    }
    return (
      <>
        <PaymentProvider>
          {Object.keys(sessionDetails)?.length > 0 && !hmh && navBar}
          <Routes>
            <Route exact path="/" element={layout} />
            <Route exact path="/:token" element={layout} />
            <Route exact path="/payment-completion-handler" element={paymentcompletionhandler} />
            <Route exact path="/finalStatus" element={success} />
          </Routes>
        </PaymentProvider>
      </>
    );
  };

  // return getTemplate("domestic_checkout");
  return (isIframePopUp || windowPopupName) === 'sdk_form_popup' ? <>
    <Loading
      text="Loading"
      subText=""
    />
  </> : getTemplate("domestic_checkout");
};

export default BoxPayCheckout;
