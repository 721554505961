import React, { createContext, useState } from "react";
const PaymentContext = createContext();
export default PaymentContext;

export const PaymentProvider = (props) => {
    const [popUpWindowIframeIos, setPopUpWindowIframeIos] = useState();
    const [isDccSelected, setIsDccSelected] = useState('');
    const [showErroModalForUpiIntent, setShowErroModalForUpiIntent] = useState(false);
    const [showCancelBtn, setShowCancelBtn] = useState(false);
    const [cancelBtnFunc, setCancelBtnFunc] = useState(() => { });

    const values = {
        popUpWindowIframeIos,
        setPopUpWindowIframeIos,
        isDccSelected,
        setIsDccSelected,
        showErroModalForUpiIntent,
        setShowErroModalForUpiIntent,
        showCancelBtn, setShowCancelBtn,
        cancelBtnFunc, setCancelBtnFunc
    }

    return (
        <PaymentContext.Provider value={values}>
            {props.children}
        </PaymentContext.Provider>
    )
}