import React, { useContext, useEffect, useRef } from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import animationData from "../lotties/BoxPayLogo.json";
import TranslateText from "../../resuableComponents/TranslateText";
import "./loading.css";
import { useSelector } from "react-redux";
import { formatOtpTime } from "../../../Utils/utils";
import PaymentContext from "../../../context/payment.context";
import CustomButton from "../../resuableComponents/CustomButton";

export default function Loading({ text, subText, redirectionName }) {
  const { t } = useTranslation();
  const { cancelBtnFunc, showCancelBtn } = useContext(PaymentContext);
  const timerHiddenRef = useRef();
  const timerValueRef = useRef();
  const timerIntervalRef = useRef();
  const isTimerEnabled = useSelector((state) => state.paymentSessionDetails.isTimerEnabled);
  const timerInSec = useSelector((state) => state.paymentSessionDetails.timerInSec);

  useEffect(() => {
    if (isTimerEnabled) {
      timerIntervalRef.current = setInterval(() => {
        let time = Number(timerHiddenRef?.current?.innerHTML);
        if (time && time > 0) {
          timerHiddenRef.current.innerHTML = time - 1;
          timerValueRef.current.innerHTML = formatOtpTime(time - 1, 0);
        } else {
          clearInterval(timerIntervalRef?.current)
        }
      }, 1000);
    }
  }, [isTimerEnabled]);

  return (
    <div className="loadingMainDiv">
      <div className="innerText">
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
          style={{ width: "48px", height: "48px" }}
          speed={4.5}
        />
        {redirectionName?.length ? (
          <Typography className="loadingText">
            <TranslateText label={"REDIRECTING_TO"}>Please wait. We are redirecting you to</TranslateText> {redirectionName}'s <TranslateText label={"PAGE"}>page</TranslateText>
          </Typography>
        ) : (
          <Typography className="loadingText">{text}</Typography>
        )}
        <Typography className="loadingSubText">{subText}</Typography>
        {
          isTimerEnabled && (
            <>
              <div>Redirecting in</div>
              <b><div ref={timerValueRef}> {formatOtpTime(timerInSec, 0)}</div></b>
              <div ref={timerHiddenRef} style={{ display: 'none' }}>
                {timerInSec}
              </div>
            </>
          )
        }

        {
          showCancelBtn && (
            <>
              <CustomButton
                text={"Cancel"}
                buttonType={"primary-btn"}
                styles={{ padding: "5px 15px", borderRadius: '4px', marginTop: '1rem' }}
                padding={"5px 15px"}
                onClick={cancelBtnFunc}
              />
            </>
          )
        }
      </div>
    </div>
  );
}
