import React, { useState } from "react";
import { Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const DescriptionModal = ({description,isModalOpen,setIsModalOpen}) => {

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <span className="semiBold16Heading">DESCRIPTION</span>
            <CloseOutlined
              onClick={handleCancel}
              style={{ cursor: "pointer" }}
            />
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={null}
        bodyStyle={{ maxHeight: "80vh", overflowY: "auto", padding: "10px" }} 
      >
        <div style={{ whiteSpace: "pre-line", lineHeight: 1.6 , fontSize:"13px" }}>
         {description}
        </div>
      </Modal>
    </div>
  );
};

export default DescriptionModal;
