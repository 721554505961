import React from "react";

const NotFound = ({ error, resetErrorBoundary }) => {

    const refresh = () => {
        window.location.reload()
    }

    return (
        <div style={{
            textAlign: "center",
            "display": "flex",
            flexDirection: "column",
            maxWidth: "400px",
            margin: "0 auto",
            height: "90vh",
            justifyContent: "center"
        }}>
            <img src="/assets/errorImage/TechnicalError.svg" />
            <p className="semiBold24Heading" style={{ marginTop: "20px" }}>Oops! Something Went Wrong</p>
            <p className="regular14Heading" style={{ marginTop: "5px", marginBottom: "20px" }}>Please Check the URL or click on refresh</p>
            <button
                onClick={refresh}
                style={{
                    padding: "10px 20px",
                    backgroundColor: "#1890FF",
                    color: "#fff",
                    border: "1px solid #1890FF",
                    borderRadius: "5px",
                    cursor: "pointer",
                }}
            >
               Refresh
            </button>
        </div>
    );
};

export default NotFound;
