import React, { useState } from "react";
import "./style.css";
import { Divider, Typography } from "antd";
import TranslateText from "../../resuableComponents/TranslateText";
import CustomButton from "../../resuableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrencyCode } from "../../../slices/PaymentSessionDetailsSlice";
import CurrencyInput from "./CurrencyInput";
import DescriptionModal from "./DescriptionModal";
import { handleTotalPaymentSummaryValue } from "../../../slices/HandleAdditionalComponentsSlice";
import BottomFooter from "../checkoutFooter/BottomFooter";
import { removeCommasAndConvertToInteger } from "../../../Utils/utils";
import { setOnDemandAmount, setOnDemandLoaded } from "../../../slices/CommonPersistedSlice";

function OnDemand({ isMobile }) {
  const [inputValue, setInputValue] = useState("");
  const [viewMore, setViewMore] = useState(false);
  const [error, setError] = useState("");

  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const truncatedDescription = (text, length) =>
    text?.length > length ? `${text.substring(0, length)}... ` : text;

  const dispatch = useDispatch();

  const handleSubmitForm = () => {
    const formattedInput = removeCommasAndConvertToInteger(inputValue)
    dispatch(handleTotalPaymentSummaryValue(formattedInput));
    dispatch(setOnDemandAmount(formattedInput))
    dispatch(setOnDemandLoaded(true))
  };

  return (
    <div className="onDemandContainer">
      <div className="onDemandCardContainer">
        <div className="onDemandCard ">
          <div className="leftCard">
            <Typography className="onDemand-heading">
              <TranslateText label={"PAYMENT_REQUESTED_BY"}>
                Payment Requested BY
              </TranslateText>
            </Typography>
            <Typography
              className="semiBold26Heading"
              style={{ marginTop: "-10px" }}
            >
              {sessionDetails?.merchantDetails?.merchantName}
            </Typography>
            {sessionDetails?.title && (
              <div style={{ margin: "20px 0 " }}>
                <Typography className="regular14Heading onDemand-subheading  ">
                  <TranslateText label={"PAYMENT_FOR"}>
                    {" "}
                    Payment For
                  </TranslateText>
                </Typography>
                <Typography
                  className="regular14Heading "
                  style={{ fontSize: isMobile ? "14px" : "auto" }}
                >
                  {sessionDetails?.title}
                </Typography>
              </div>
            )}
            {sessionDetails?.description &&
              sessionDetails?.description?.length > 0 && (
                <div style={{ margin: "20px 0 " }}>
                  <Typography className="regular14Heading  onDemand-subheading ">
                    <TranslateText label={"ON_DEMAND_DESCRIPTION"}>
                      Description
                    </TranslateText>
                  </Typography>
                  {isMobile ? (
                    <>
                      <Typography
                        className={`regular14Heading showElipsis payment-link-description `}
                        style={{ fontSize: "14px" }}
                      >
                        {sessionDetails?.description}
                      </Typography>

                      {sessionDetails?.description?.length > 60 && (
                        <span
                          onClick={() => setViewMore(true)}
                          className="semiBold12Heading"
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            display: "block",
                          }}
                        >
                          <TranslateText label={"VIEW_MORE"}>
                            view more
                          </TranslateText>
                        </span>
                      )}
                    </>
                  ) : (
                    <div className="descriptionContainer">
                      <Typography className="regular14Heading  " style={{ whiteSpace: "pre-wrap" }}>
                        {viewMore
                          ? sessionDetails?.description
                          : truncatedDescription(
                            sessionDetails?.description,
                            300
                          )}
                        {sessionDetails?.description?.length > 300 && (
                          <span
                            className="semiBold12Heading"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => setViewMore((state) => !state)}
                          >
                            {" "}
                            {viewMore ? (
                              <TranslateText label={"READ_LESS"}>
                                Read Less
                              </TranslateText>
                            ) : (
                              <TranslateText label={"READ_MORE"}>
                                Read More
                              </TranslateText>
                            )}
                          </span>
                        )}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
          </div>
          <div className="rightCard">
            <Typography className="semiBold14Heading">
              <TranslateText label={"ENTER_AMOUNT"}>Enter Amount</TranslateText>
            </Typography>
            <div>
              <CurrencyInput
                currency={currencyCode}
                value={inputValue}
                setValue={setInputValue}
                error={error}
                setError={setError}
                isMobile={isMobile}
              />
            </div>
            {!isMobile && <div className="onDemandPaymentButtonContainer">
              <CustomButton
                onClick={handleSubmitForm}
                text={
                  <div
                    className={`dynamic-font-family ${!inputValue ? "" : "dynamic-button-text"}`}
                    style={{
                      color: "inherit !important",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <img
                      src={
                        !inputValue || error
                          ? "/assets/ondemandPayDisable.svg"
                          : "/assets/onDemandPaymentButton.svg"
                      }
                    />
                    <TranslateText label={"PROCEED_SECURELY"}>
                      Proceed Securely
                    </TranslateText>
                  </div>
                }
                block={true}
                buttonType={"primary-btn"}
                padding={"10px 50px"}
                htmlType="submit"
                disabled={!inputValue || error}
              />
            </div>}
          </div>
          {isMobile && (
            <DescriptionModal
              description={sessionDetails?.description || ""}
              isModalOpen={viewMore}
              setIsModalOpen={setViewMore}
            />
          )}
        </div>
      </div>
      {!isMobile && <div style={{ display: "flex", justifyContent: "center", background: "white" }}>
        < div className="domestic-layout-footer" style={{ background: "white" }}>
          <BottomFooter />
        </div>
      </div>}
      {
        isMobile && <div className="onDemandPaymentButtonContainer">
          <CustomButton
            onClick={handleSubmitForm}
            text={
              <div
                className={`dynamic-font-family `}
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <img
                  src={
                    !inputValue || error
                      ? "/assets/ondemandPayDisable.svg"
                      : "/assets/onDemandPaymentButton.svg"
                  }
                />
                <TranslateText label={"PROCEED_SECURELY"}>
                  Proceed Securely
                </TranslateText>
              </div>
            }
            block={true}
            buttonType={"primary-btn"}
            padding={"10px 50px"}
            htmlType="submit"
            disabled={!inputValue || error}
          />
        </div>
      }
    </div >
  );
}

export default OnDemand;
