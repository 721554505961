import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { updateStroage } from "../Utils/utils";

const baseUrl = process.env.REACT_APP_BASE_URL;

const getErrorMessage = (errResponse) => {
  if (errResponse?.data !== undefined) {
    if (errResponse.data.fieldErrorItems?.length > 0) {
      return errResponse.data.fieldErrorItems
        .map((errItem) => `${errItem.message}`)
        .join("\n");
    }
    return errResponse.data.message;
  }
  return errResponse?.statusText;
};

export const
  initiatePayment = createAsyncThunk(
    "paymentInitiation/initiatePayment",
    async ({ token, payload }, { rejectWithValue }) => {
      try {
        const headers = {
          "X-Request-Id": uuidv4(),
          "X-Caller-Url": `${window.location.href}`,
        };
        const sessionToken = localStorage.getItem("sessionToken");

        if (sessionToken) {
          headers.Authorization = `Session ${sessionToken}`;
        }

        const response = await axios.post(
          `${baseUrl}/checkout/sessions/${token}`,
          payload,
          { headers }
        );
        if (
          response.data?.status?.status === "Rejected" ||
          response.data?.status?.status === "Failed"
        ) {
          return rejectWithValue(response?.data);
        }
        return response.data;
      } catch (error) {
        return rejectWithValue(getErrorMessage(error.response));
      }
    }
  );

const initialState = {
  paymentInitiationLoading: false,
  paymentInitiationError: "",
  paymentInitiationData: null,
  failedModal: false,
  paymentInitiationRejectedMsg: "",
};

export const paymentInitiationSlice = createSlice({
  name: "paymentInitiation",
  initialState,
  reducers: {
    resetPaymentInitiation(state) {
      state.paymentInitiationError = "";
      state.paymentInitiationData = null;
      state.isPaymentRejectedOrFailed = false;
      state.paymentInitiationRejectedMsg = "";
    },
    showFailedModal(state, action) {
      state.failedModal = action.payload;
    },
    resetPaymentInitiationLoading(state) {
      state.paymentInitiationLoading = false;
    },
  },
  extraReducers: {
    [initiatePayment.pending]: (state) => {
      state.paymentInitiationLoading = true;
    },
    [initiatePayment.fulfilled]: (state, action) => {
      state.paymentInitiationData = action.payload;
      state.isPaymentRejectedOrFailed = false;
      state.paymentInitiationRejectedMsg = action.payload.status.reason;
      state.paymentInitiationError = "";
      // if (action.payload.status?.status?.toLowerCase() === 'approved') state.paymentInitiationLoading = false;
      if (action?.payload?.paymentMethod?.brand !== 'UpiIntent' || action.payload.status?.status?.toLowerCase() === 'approved') state.paymentInitiationLoading = false;
      updateStroage("session", "update", "t_id", action.payload.transactionId);
    },
    [initiatePayment.rejected]: (state, action) => {
      state.paymentInitiationLoading = false;
      state.paymentInitiationData = null;
      state.paymentInitiationError = action.payload?.status?.reason;
      state.isPaymentRejectedOrFailed = true;
      if (action.payload.status?.reasonCode?.startsWith("UF")) {
        state.paymentInitiationError = action.payload?.status?.reason;
      } else {
        state.paymentInitiationError = "";
      }
    },
  },
});

export const { resetPaymentInitiation, showFailedModal, resetPaymentInitiationLoading } = paymentInitiationSlice.actions;

export default paymentInitiationSlice.reducer;
