import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
const persistConfig = {
    key: 'commonPersisted', // Unique key for this slice
    storage, // Use session storage
};

const initialState = {
    selectedLanguage: "",
    onDemandAmount: null,
    onDemandLoaded: false
};

const commonPersistedSlice = createSlice({
    name: "commonPersisted",
    initialState,
    reducers: {
        setSelectedLanguage(state, action) {
            state.selectedLanguage = action.payload;
        },
        setOnDemandAmount(state, action) {
            state.onDemandAmount = action.payload
        },
        setOnDemandLoaded: (state, acton) => {
            state.onDemandLoaded = acton.payload
        }
    },
    extraReducers: {}
});

export const persistedCommonSliceReducer = persistReducer(persistConfig, commonPersistedSlice.reducer);
export const {
    setSelectedLanguage,
    setOnDemandAmount,
    setOnDemandLoaded
} = commonPersistedSlice.actions;

export default commonPersistedSlice.reducer;
