import React from "react";
import { Button, ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import { selectIsMobileLayout } from "../../slices/MobileLayoutSlice";
import "./customButton.css";

const CustomButton = ({
  text = "Submit",
  backgroundColor = "rgba(24, 144, 255, 1)",
  fontSize = "14px",
  fontWeight = 400,
  padding = "9px 0px",
  lineHeight = "20px",
  fontFamily = "Poppins",
  display = "flex",
  justifyContent = "center",
  block = false,
  htmlType = "submit",
  disabled = false,
  buttonType = "primary-btn",
  onClick = () => { },
  containerType = "primary",
  icon = "",
  loading,
  styles = {}
}) => {
  const theme = useSelector(
    (state) =>
      state?.paymentSessionDetails?.sessionDetails?.merchantDetails
        ?.checkoutTheme
  );
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));

  function debounce(func, delay = 2000) {
    let timer;
    return function () {
      const context = this;
      const args = arguments;
      if (!timer) {
        func.apply(context, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, delay);
    };
  }

  const dbFunc = debounce((event, onClick) => {
    onClick(event);
  });

  const buttonColor = () => {
    if (containerType == "") {
      return "rgba(0, 0, 0, 0.85)";
    }
    if (containerType === "link") {
      return theme?.primaryButtonColor;
    } else {
      return theme?.primaryTextColor;
    }
  };


  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        token: {
          colorPrimary: containerType
            ? buttonType === "primary-btn"
              ? theme?.primaryButtonColor || backgroundColor
              : backgroundColor
            : "#D9D9D9",
          borderRadius: theme?.buttonShape === "RECTANGLE" ? "0px" : "6px",
          boxShadow: "0 1px 0 rgba(1, 2, 2, 0.1)",
        },
      }}
    >
      <Button
        htmlType={htmlType}
        block={block}
        loading={loading}
        disabled={disabled || loading}
        type={containerType}
        style={{
          padding: [padding],
          fontWeight: [fontWeight],
          fontSize: [fontSize],
          lineHeight: [lineHeight],
          display: [display],
          justifyContent: [justifyContent],
          fontFamily: [theme?.font ? theme?.font : fontFamily],
          color: [buttonColor()],
          ...styles
        }}
        onClick={(event) => dbFunc(event, onClick)}
        icon={icon}
      >
        {text}
      </Button>
    </ConfigProvider>
  );
};
export default CustomButton;
