import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookieValue, getStroage, isEmpty } from "../Utils/utils";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getSavedIntsruments = createAsyncThunk(
  "savedInstruments/getSavedIntsruments",
  async ({ checkoutToken, shopperRef }, { rejectWithValue }) => {
    try {
      const sessionToken = localStorage.getItem("sessionToken");
      if (isEmpty(shopperRef)) return
      const response = await axios.get(
        `${baseUrl}/checkout/sessions/${checkoutToken}/shoppers/${shopperRef}/recommended-instruments`,
        {
          headers: {
            Authorization: `Session ${sessionToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response);
    }
  }
);

const initialState = {
  savedInstrumentsLoading: false,
  savedInstruments: [],
  selectedIntrument: null,
  error: null,
};

export const savedInstrumentSlice = createSlice({
  name: "savedInstrument",
  initialState,
  reducers: {
    updateSelectedIntruments(state, action) {
      state.selectedIntrument = action.payload;
    },
    removeSelectedIntruments(state, action) {
      state.selectedIntrument = null;
    },
  },
  extraReducers: {
    [getSavedIntsruments.pending]: (state) => {
      state.savedInstrumentsLoading = true;
    },
    [getSavedIntsruments.fulfilled]: (state, action) => {
      state.savedInstrumentsLoading = false;
      state.savedInstruments = action.payload;
    },
    [getSavedIntsruments.rejected]: (state, action) => {
      state.savedInstrumentsLoading = false;
      state.error = action.payload?.data?.message;
    },
  },
});

export const { updateSelectedIntruments, removeSelectedIntruments } =
  savedInstrumentSlice.actions;

export const selectSavedInstruments = (state) =>
  state.savedInstrument.savedInstruments;
export const selectIsSavedInstrumentsLoding = (state) =>
  state.savedInstrument.savedInstrumentsLoading;
export const selectSelectedIntrument = (state) =>
  state.savedInstrument.selectedIntrument;

export default savedInstrumentSlice.reducer;
