import { Divider, Input, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./paymentSummary.css";
import "../fontStyling.css";
import {
  selectCurrencyCode,
  selectCurrencySymbol,
  selectFontFamily,
} from "../../../slices/PaymentSessionDetailsSlice";
import {
  selectActivePaymentMethod,
} from "../../../slices/PaymentButtonSlice";
import {
  blockInvalidChar,
  formatInputAmount,
  generateDynamicStyling,
  getNetAmount,
  getSanitizedAmount,
  isEmpty,
  numberToCurrency,
} from "../../../Utils/utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { ReactComponent as EDIT_ICON } from '../../../assets/edit_icon.svg'
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import {
  handleTotalPaymentSummaryError,
  handleTotalPaymentSummaryValue,
} from "../../../slices/HandleAdditionalComponentsSlice";
import TranslateText from "../../resuableComponents/TranslateText";
import { selectSelectedIntrument } from "../../../slices/SavedInstrumentSlice";
import { MAXIMUM_NUMBER_AMOUNT } from "../../../staticData";
import { setOnDemandAmount, setOnDemandLoaded } from "../../../slices/CommonPersistedSlice";

const PaymentSummary = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [items, setItems] = useState(0);
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const selectedInstrument = useSelector((state) => selectSelectedIntrument(state));
  const paymentDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails.paymentDetails);
  const sessionDetailsLoading = useSelector((state) => state.paymentSessionDetails.sessionDetailsLoading);
  const showTotalPaymentSummaryField = useSelector((state) => state.additionalComponents.showTotalPaymentSummaryField);
  const totalPaymentSummaryValue = useSelector((state) => state.additionalComponents.totalPaymentSummaryValue);
  const showTotalPaymentSummaryError = useSelector((state) => state.additionalComponents.showTotalPaymentSummaryError);
  const sampleToken = useSelector((state) => state.paymentSessionDetails.sampleToken);
  const fontFamily = useSelector((state) => selectFontFamily(state));
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const [paymentSummary, setPaymentSummary] = useState();
  const isDccQuotationSelected = useSelector((state) => state.cardDetails.isDccQuotationSelected);
  const dccQuotation = useSelector(state => state.cardDetails.dccQuotation);
  const dccMoney = dccQuotation?.dccQuotationDetails?.dccMoney?.amount;
  const issuerCurrencyDetails = useSelector(state => state.cardDetails.issuerCurrencyDetails);
  const activePaymentMethod = useSelector((state) => selectActivePaymentMethod(state));

  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
  }, [sessionDetails]);
  useEffect(() => {
    if (paymentDetails && currencyCode) {
      let paymentSummaryDictionary = [
        {
          title: 'Subtotal',
          label: 'SUBTOTAL',
          value:
            !sampleToken && !showTotalPaymentSummaryField
              ? numberToCurrency(
                paymentDetails?.order?.originalAmount || paymentDetails?.money?.amount,
                currencyCode
              )
              : null,
        },
        {
          title: "Shipping Cost",
          label: 'SHIPPING_COST',
          value:
            !sampleToken && paymentDetails?.order?.shippingAmount != null
              ? numberToCurrency(paymentDetails?.order?.shippingAmount, currencyCode)
              : null,
        },
        // {
        //   ...!isEmpty(selectedInstrument) && !sampleToken ? {
        //     title: "Interest Charged By Bank",
        //     label: 'INTEREST_CHARGED_BY_BANK',
        //     value: numberToCurrency(selectedInstrument?.emiMethod?.bankChargedInterestAmount, currencyCode),
        //   } : {}
        // },
        {
          title: "Taxes and Fees",
          label: "TAXES_AND_FEES",
          value: numberToCurrency(paymentDetails?.order?.taxAmount, currencyCode),
        },
        {
          label: "DISCOUNT",
          title: "Discount",
          value: numberToCurrency(paymentDetails?.order?.totalDiscountedAmount, currencyCode),
          style: { color: '#2DA94F' }
        },
        {
          ...!isEmpty(selectedInstrument) && !sampleToken && selectedInstrument?.emiMethod?.merchantBorneInterestAmount > 0 ? {
            title: selectedInstrument?.emiMethod?.applicableOffer?.discount?.type === 'NoCostEmi' ? "No Cost EMI Discount" : "Low Cost EMI Discount",
            label: selectedInstrument?.emiMethod?.applicableOffer?.discount?.type === 'NoCostEmi' ? "NO_COST_EMI_DISCOUNT" : "LOW_COST_EMI_DISCOUNT",
            value: numberToCurrency(
              selectedInstrument?.emiMethod?.merchantBorneInterestAmount,
              currencyCode
            ),
            style: { color: '#2DA94F' }
          } : {}
        },
      ];
      let itemsPresent = paymentSummaryDictionary?.filter(
        (object) => object?.value != null
      )?.length;
      setItems(itemsPresent);
      setPaymentSummary(paymentSummaryDictionary);
    }
  }, [paymentDetails, currencyCode, selectedInstrument]);
  const totalPaymentErrors = (number) => {
    if (!number) {
      dispatch(handleTotalPaymentSummaryError(t("PLEASE_ENTER_AMOUNT")));
    } else if (number.startsWith("0") && number.length > 0) {
      dispatch(handleTotalPaymentSummaryError(t("AMOUNT_GREATER_THAN_ZERO")));
    } else if (number * 1 > MAXIMUM_NUMBER_AMOUNT) {
      dispatch(handleTotalPaymentSummaryError(t("HIGH_AMOUNT_ERROR_ON_DEMAND")));

    } else {
      dispatch(handleTotalPaymentSummaryError(""));
    }
  };
  const handleTotalPaymentAmount = (e) => {
    const amount = e.target.value;
    let sanitizedAmount = getSanitizedAmount(amount);
    if (amount * 1 > MAXIMUM_NUMBER_AMOUNT) return
    e.target.value = sanitizedAmount;
    totalPaymentErrors(sanitizedAmount);
    dispatch(handleTotalPaymentSummaryValue(sanitizedAmount));
    dispatch(setOnDemandAmount(sanitizedAmount))
  };
  const blockInvalidCharNumber = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleEditAmount = () => {
    dispatch(setOnDemandLoaded(false))
  }
  useEffect(() => {
    const handleLanguageChange = () => {
      totalPaymentErrors(totalPaymentSummaryValue)
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, totalPaymentSummaryValue]);

  const getAmount = (isDcc) => getNetAmount({ isDcc, activePaymentMethod, selectedInstrument, dccMoney, amount: paymentDetails?.money?.amount });

  return (
    <>
      {paymentDetails && (
        <>
          {sessionDetailsLoading ? (
            <Skeleton active size="large" />
          ) : (
            <>
              {!isMobileLayout && (
                <div className="deliveryAddressHeader">
                  <div className="headerDiv">
                    <img
                      src="/assets/cardHeader/PaymentSummary.svg"
                      alt="delivery"
                      style={{ height: "16px", widht: "16px" }}
                    />
                    <Typography className="deeliveryHeading ">
                      <TranslateText label={"PAYMENT_SUMMARY"}>Payment Summary</TranslateText>
                    </Typography>
                  </div>
                </div>
              )}
              <div className="domestic-payment-summary-root dynamic-font-family">
                <div className="payment-summary-desc dynamic-font-family">
                  {paymentSummary?.map((object, i) => {
                    return (
                      object?.value && (
                        <div className="payment-summary-item dynamic-font-family" key={`summary-${i}`}>
                          <Typography
                            className="payment-summary-item-title dynamic-font-family"
                            style={{ fontFamily: fontFamily }}
                          >
                            <TranslateText label={object.label}>{t(`${object?.title}`)}</TranslateText>
                          </Typography>
                          <Typography
                            className="payment-summary-item-value dynamic-font-family"
                            style={{ fontFamily: fontFamily, ...object?.style }}
                          >
                            <span className="currency-symbol">
                              {object?.style ? '- ' : ''}
                              {getSymbolFromCurrency(currencyCode)}{" "}
                            </span>
                            {object?.value}
                          </Typography>
                        </div>
                      )
                    );
                  })}
                </div>

                {paymentDetails?.money?.amountLocaleFull && (
                  <div
                    className="payment-summary-total"
                    style={{ marginTop: items > 0 ? "16px" : "0px" }}
                  >
                    {items > 0 && (
                      <Divider
                        style={{
                          marginBottom: items > 0 ? "12px" : "0",
                          background: "#F1F1F1",
                        }}
                      />
                    )}
                    <div className="payment-summary-item">
                      <Typography className="semiBold18Heading payment-summary-total-title dynamic-font-family">
                        <TranslateText label={"TOTAL"}>Total amount</TranslateText>
                      </Typography>

                      {showTotalPaymentSummaryField ? (
                        <div style={{ width: "50%", display: "flex", justifyContent: "flex-end", gap: "10px", alignItems: "center" }} >
                          <Typography className="semiBold18Heading payment-summary-total-value dynamic-font-family">
                            <span className="currency-symbol"> {getSymbolFromCurrency(currencyCode)}  </span>
                            {totalPaymentSummaryValue ? totalPaymentSummaryValue : ""}
                          </Typography>

                          <EDIT_ICON width={20} style={{ cursor: "pointer" }} onClick={handleEditAmount} />
                        </div>
                      ) : (
                        <Typography className="semiBold18Heading payment-summary-total-value dynamic-font-family">
                          {
                            isDccQuotationSelected ? (
                              <>
                                <span className="currency-symbol"> {issuerCurrencyDetails.currencyCode}  </span>
                                {numberToCurrency(getAmount(isDccQuotationSelected), issuerCurrencyDetails.currencyCode)}
                              </>
                            ) : (
                              <>
                                <span className="currency-symbol"> {getSymbolFromCurrency(currencyCode)}  </span>
                                {showTotalPaymentSummaryField ? totalPaymentSummaryValue : numberToCurrency(getAmount(), currencyCode)}
                              </>
                            )
                          }
                        </Typography>
                      )}
                    </div>
                    {showTotalPaymentSummaryField && (
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "30%" }}></div>
                        <div>
                          <Typography
                            className="regular12Heading dynamic-font-family"
                            style={{ color: "var(--Red-800)" }}
                          >
                            {showTotalPaymentSummaryError}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {
                  !isMobileLayout && sessionDetails?.merchantDetails?.convenienceFeeChargedToShopper && (
                    <div className="convenience-fee-msz">
                      <TranslateText label={"CONVENIENCE_FEE_MSZ"}>
                        A convenience fee may be charged while completing your payment
                      </TranslateText>
                    </div>
                  )
                }
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentSummary;
