import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { paymentSession, selectFrontendBackUrl, selectFrontReturnUrl } from '../../../slices/PaymentSessionDetailsSlice';
import Loading from '../loadingScreen/Loading';
import { useTranslation } from 'react-i18next';
import { getAllQueryParams, isEmpty, updateStroage } from '../../../Utils/utils';
import { getTransactionStatus, selectTransactionData } from '../../../slices/TransactionIdSlice';

const PaymentCompletionHandler = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const backUrl = useSelector((state) => selectFrontendBackUrl(state));
    const returnUrl = useSelector((state) => selectFrontReturnUrl(state));
    const parsedUrl = new URL(window.location.href);
    const params = new URLSearchParams(parsedUrl.search);
    const paramsObject = {};
    const sessionToken = params.get('sessionToken');
    const transactionId = params.get('transactionId');
    const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
    const paymentSuccessData = useSelector((state) => selectTransactionData(state));
    useEffect(() => {
        const frontendBackUrl = sessionDetails?.paymentDetails?.frontendBackUrl;
        if (!isEmpty(frontendBackUrl) && !isEmpty(paymentSuccessData)) {
            const frontendBackUrl = sessionDetails?.paymentDetails?.frontendBackUrl;
            if ((paymentSuccessData?.status === "FAILED" || paymentSuccessData?.status === "Rejected") && frontendBackUrl) {
                window.location.replace(frontendBackUrl);
            } else {
                window.location.replace(`/finalStatus`)
            }
        }
    }, [sessionDetails, paymentSuccessData])

    useEffect(() => {
        if (!isEmpty(sessionToken) && !isEmpty(transactionId)) {
            // non seemless flow
            updateStroage("session", "clear");
            updateStroage("session", "update", "tkn", sessionToken);
            updateStroage("session", "update", "t_id", transactionId);
            dispatch(paymentSession({ token: sessionToken }));
            dispatch(getTransactionStatus({ token: sessionToken, transactionId: transactionId }));
        } else {
            // seemless flow
            const newUrl = getAllQueryParams(returnUrl ? returnUrl : backUrl)
            window.location.href = newUrl;
        }
    }, []);

    return (
        <>
            <Loading
                text={`${t("LOADING")}...`}
                subText=""
            />
        </>
    )
}

export default PaymentCompletionHandler