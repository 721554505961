import React, { useEffect, useRef, useState } from "react";
import "./CurrencyInput.css";
import getSymbolFromCurrency from "currency-symbol-map";
import { formatInputAmount, numberToWords, removeCommasAndConvertToInteger } from "../../../Utils/utils";
import { t } from "i18next";
import { MAXIMUM_NUMBER_AMOUNT } from "../../../staticData";
import { useSelector } from "react-redux";

const CurrencyInput = ({ currency, value, setValue, setError, error, isMobile }) => {
  const [textWidth, setTextWidth] = useState(1);
  const inputRef = useRef(null);
  const totalPaymentSummaryValue = useSelector((state) => state.additionalComponents.totalPaymentSummaryValue);

  const measureTextWidth = (value) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const inputStyles = window.getComputedStyle(inputRef.current);
    context.font = `${inputStyles.fontSize} ${inputStyles.fontFamily}`;
    return context.measureText(value).width / 2;
  };
  const handleInputChange = (value) => {
    let inputValue = value?.replace(/[^0-9]/g, "");
    setError("");
    if (!inputValue) {
      setError(t("NO_AMOUNT_ERROR_ON_DEMAND"));
    } else {
      const numericValue = parseInt(inputValue, 10);
      if (numericValue < 1) {
        setError(t("NO_AMOUNT_ERROR_ON_DEMAND"));
      } else if (inputValue.length > 8) {
        setError(t("HIGH_AMOUNT_ERROR_ON_DEMAND"));
        return;
      } else if (numericValue > MAXIMUM_NUMBER_AMOUNT) {
        setError(t("HIGH_AMOUNT_ERROR_ON_DEMAND"));
      } else {
        setError("");
      }
    }

    const formattedValue = formatInputAmount(inputValue, currency);
    setTextWidth(measureTextWidth(formattedValue));
    setValue(formattedValue);
  };

  useEffect(() => {
    if (totalPaymentSummaryValue) {
      handleInputChange(`${totalPaymentSummaryValue}`)
    }
  }, [])



  return (
    <div className="currency-input-wrapper">
      <div className="currency-input">
        <span
          className="currency-symbol-onDemand regular48Heading"
          style={{ left: `calc(50% - ${(isMobile ? 35 : 45) + textWidth}px)` }}
        >
          {getSymbolFromCurrency(currency)}
        </span>
        <input
          ref={inputRef}
          type="text"
          value={formatInputAmount(value, currency)}
          onChange={(e) => handleInputChange(e.target.value)}
          placeholder="0"
        />
      </div>
      {error && (
        <p className="regular12Heading" style={{ color: "#FF4D4F" }}>
          {error}
        </p>
      )}
      <p className="amount-in-words" style={{ marginTop: "15px" }}>
        {value && value !== 0 ? numberToWords(currency, value) : ""}
      </p>
    </div>
  );
};

export default CurrencyInput;