import { Typography } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { buildPaymentRequest } from "../../../../../Utils/paymentUtils";
import { initiatePayment, showFailedModal } from "../../../../../slices/PaymentInitiationSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../loadingScreen/Loading";
import { handleIntentAppName } from "../../../../../slices/UpiSlice";
import { handleTotalPaymentSummaryError } from "../../../../../slices/HandleAdditionalComponentsSlice";
import { paymentInitiatedEvent } from "../../../../../Utils/uiAnalytics";
import "./upiMobile.css";
import { getPopDimensionDetails, iframeLoadingComponent, isFireFox } from "../../../../../Utils/utils";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import PaymentContext from "../../../../../context/payment.context";

const UpiIntent = ({ upiData }) => {
  const dispatch = useDispatch();
  const { popUpWindowIframeIos, setPopUpWindowIframeIos } = useContext(PaymentContext);
  const { t } = useTranslation();
  const isIphone = useSelector((state) => state.mobileLayout.isIphone);
  const updatedShopperDetails = useSelector((state) => state.paymentSessionDetails.updatedShopperDetails);
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const { paymentInitiationData, paymentInitiationLoading } = useSelector((state) => state.paymentInitiation);
  const statusResponse = useSelector((state) => state.finalStatus.activeStatusData);
  const totalPaymentSummaryValue = useSelector((state) => state.additionalComponents.totalPaymentSummaryValue);
  const showTotalPaymentSummaryField = useSelector((state) => state.additionalComponents.showTotalPaymentSummaryField);
  const params = new URLSearchParams(window.location.search);
  const [showLoading, setShowLoading] = useState(false);
  const popWindowIframeRef = useRef();
  const showGpay = params.get("gp");
  const showPhonepe = params.get("pp");
  const showPaytm = params.get("pm");
  const hmh = params.get("hmh");

  const isIframe = window.self !== window.top;
  const upiIntentApps = [
    {
      id: "GPay",
      name: t("GPAY"),
      logo: "/assets/intent/google-pay.svg",
      visibilty: !hmh || (hmh && showGpay) ? true : false,
      key: "tez",
    },
    {
      id: "PhonePe",
      name: t("PHONEPE"),
      logo: "/assets/intent/phonepe.svg",
      visibilty: !hmh || (hmh && showPhonepe) ? true : false,
      key: "PhonePe",
    },

    {
      id: "PayTm",
      name: t("PAYTM"),
      logo: "/assets/intent/paytm.svg",
      visibilty: !hmh || (hmh && showPaytm) ? true : false,
      key: "paytmmp",
    },
    {
      id: "Others",
      name: t("OTHERS"),
      logo: "/assets/intent/others.svg",
      visibilty: true,
      // visibilty: (hmh && (showPaytm || showPhonepe || showGpay)) ? false : true,
      key: "others",
    },
  ];

  const setPopupWindowForIframe = () => {
    const { width, height, left, top } = getPopDimensionDetails();
    popWindowIframeRef.current = window.open('', '_blank', `width=${width},height=${height},top=${top},left=${left}`);
    const container = iframeLoadingComponent(popWindowIframeRef.current, `${t("LOADING")}...`);
    popWindowIframeRef.current.document.body.appendChild(container);
    setPopUpWindowIframeIos(popWindowIframeRef.current)
  }

  const handleIntentPay = (selectedUpiId) => {
    let paymentRequest = buildPaymentRequest(
      upiData?.instrumentTypeValue,
      updatedShopperDetails,
      sessionDetails
    );

    if (selectedUpiId !== "Others") {
      paymentRequest.payload.instrumentDetails.upiAppDetails = {
        upiApp: selectedUpiId,
      };
    }
    if (totalPaymentSummaryValue) {
      paymentRequest.payload.money = {
        amount: totalPaymentSummaryValue,
        currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
      };
    }
    let token = paymentRequest?.token;
    let payload = paymentRequest?.payload;
    if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
      dispatch(handleTotalPaymentSummaryError("Please enter amount"));
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      dispatch(initiatePayment({ token, payload }));
      paymentInitiatedEvent(sessionDetails, "Upi", "UpiIntent", dispatch);
    }
    if (isIframe && (isFireFox || isIphone)) {
      setPopupWindowForIframe();
    }
  };

  useEffect(() => {
    const handleWindowFocus = () => {
      // dispatch(resetPaymentInitiationLoading())
      popWindowIframeRef?.current?.close();
      popUpWindowIframeIos?.close();
      // setPopUpWindowIframeIos(undefined);
    };
    const handleWindowBlur = () => {
      window.addEventListener("focus", handleWindowFocus);
    };
    window.addEventListener("focus", handleWindowFocus);
    window.addEventListener("blur", handleWindowBlur);
    return () => {
      window.removeEventListener("focus", handleWindowFocus);
      window.removeEventListener("blur", handleWindowBlur);
    };
  }, []);

  useEffect(() => {
    if (paymentInitiationData !== undefined && paymentInitiationData?.paymentMethod?.brand === "UpiIntent") {
      if (!showLoading && window.document.hasFocus() && statusResponse) {
        if (
          statusResponse?.status === "PENDING" ||
          statusResponse?.status === "FAILED"
        ) {
          dispatch(showFailedModal(true));
        } else if (
          statusResponse?.status === "PAID" ||
          statusResponse?.status === "PROCESSING"
        ) {
          window.location.reload();
        }
      }
    }
  }, [paymentInitiationData, showLoading, statusResponse]);

  useEffect(() => {
    if (
      paymentInitiationData !== undefined &&
      paymentInitiationData?.paymentMethod?.brand === "UpiIntent" &&
      paymentInitiationData?.actions
    ) {
      paymentInitiationData?.actions.forEach((action) => {
        const decodedUrl = atob(action?.url);
        let matchFound = false;
        upiIntentApps.forEach((app) => {
          if (decodedUrl.toLowerCase().includes(app?.key?.toLowerCase())) {
            dispatch(handleIntentAppName(app?.name));
            matchFound = true;
          }
        });
        if (!matchFound) {
          dispatch(handleIntentAppName("UPI app"));
        }
      });
    }
  }, [paymentInitiationData]);

  return (
    <>
      {paymentInitiationLoading && (
        <Loading
          text={`${t("PLEASE_WAIT_PROCESSING_PAYMENT")}.`}
          subText={`${t("NOTE_AVOID_BACK_BUTTON")}.`}
        />
      )}
      {
        upiIntentApps?.map((app) => (
          app.id === "Others" && isIphone ? null : app.visibilty && (
            <div
              key={app.id}
              className="upi-intent-content"
              onClick={() => handleIntentPay(app.id)}
            >
              <img
                src={app?.logo}
                style={{ height: "30px", width: "30px" }}
              />
              <Typography className="upi-intnet-title dynamic-font-family">
                {app?.name}
              </Typography>
            </div>
          )
        ))
      }
    </>
  );
};

export default UpiIntent;
